import {CommonRequestKeys} from "../../../../../shared-components/common/constants/requests"
import {REQUEST_SUCCESS, REQUEST_FAILURE} from "../../../../../shared-components/store/requests/requestsReducer"
import {DISCONNECT_USER} from "../../../../../shared-components/common/actions/user/userActionTypes"

const details = (state = {}, action = {}) => {
    switch (action.type) {
        case REQUEST_SUCCESS:
            if (action.requestKeyName === CommonRequestKeys.USER_INFO) {
                return action.payload
            } else if (action.requestKeyName === CommonRequestKeys.USER_PROFILE_INFO) {
                return action.payload
            }

            return state
        case REQUEST_FAILURE:
            if (action.requestKeyName === CommonRequestKeys.USER_AUTHORITY) {
                return {}
            }
            return state
        case DISCONNECT_USER:
            return {}
        default:
            return state
    }
}

export default details

/**
 * Get user details from the store.
 */
export const getDetails = state => state

export const getProfile = state => state
