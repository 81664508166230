import {REQUEST_SUCCESS} from "../../../../shared-components/store/requests/requestsReducer"
import {PrestadomRequestKeys} from "../../../../constants/requests"
import {combineReducers} from "redux"
import {MISSION_ORDER_TO_DOWNLOAD, INFORMATION_TO_SEND} from "../../../../constants/missionStatus"
import {set, isEmpty} from "lodash"

const missions = (state = null, action = {}) => {
    if (
        action.type === REQUEST_SUCCESS &&
        (action.requestKeyName === PrestadomRequestKeys.MISSIONS_REQUEST ||
            action.requestKeyName === PrestadomRequestKeys.MISSIONS_HISTORY_REQUEST)
    ) {
        if (!isEmpty(action.payload.missions.entities.missions)) {
            return action.payload.missions.entities.missions
        } else {
            return null
        }
    } else if (
        action.type === REQUEST_SUCCESS &&
        action.requestKeyName === PrestadomRequestKeys.MISSIONS_DOWNLOAD_ODM &&
        state[action.payload.missionId].missionStatus === MISSION_ORDER_TO_DOWNLOAD
    ) {
        return set(state, [[action.payload.missionId], "missionStatus"], INFORMATION_TO_SEND)
    } else {
        return state
    }
}

export default combineReducers({missions})

export const getMissions = state => (state.missions ? state.missions : {})
