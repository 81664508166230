import {sharedGlobalTexts} from "../shared-components/styles/globalTexts"
import {fromNumberToEuros} from "../shared-components/services/currency"
import {BillinCompany} from "../constants/billingCompany"

export const defaultTexts = {
    // Add all shared global texts
    ...sharedGlobalTexts,

    // HOME
    homeRefreshDashboardErrorHeader: "Une erreur est survenue lors du rafraîchissement des données",

    // Table
    tableAction: "Actions",

    // Images
    logo: "",
    homeHeaderImage: "",

    // NOT FOUND PAGE
    // Texts
    notFoundText: "Page introuvable.",

    // ERROR PAGE
    technicalErrorText: "Il semblerait qu'une erreur technique se soit produite.",

    // UNAUTHORIZED PAGE
    // Texts
    unauthorizedText: "Oups, vous vous êtes perdu ?",

    // CONTACT PAGE
    contactPageParagraph1:
        "Merci de bien vouloir contacter nos services dédiés, en charge du bon déroulement des prestations à : ",
    contactPageParagraph2: "Pour toute autre demande, ne concernant pas une prestation merci de nous écrire à : ",

    // Recover Password
    recoverLoginPasswordBadInformations: "Informations incorrectes",
    recoverLoginPasswordSendIdentifier: "Envoyer mes identifiants",
    recoverLoginPasswordLabelIdentifier: "Saisissez votre identifiant de connexion",
    recoverLoginPasswordPlaceHolderIdentifier: "Identifiant",
    recoverLoginPasswordLabelMail: "Saisissez votre adresse mail",
    recoverLoginPasswordPlaceHolderMail: "Adresse mail",
    recoverLoginWithMailSuccess: "Un mail vous a été envoyé à l'adresse ",

    // RESET PASSWORD PAGE
    resetPasswordTokenText: "Cette opération a déjà été réalisée. Lien expiré.",
    resetPasswordSuccessText: "Votre mot de passe a bien été changé. Vous allez être redirigé dans 5 secondes.",

    // MAIL
    mailSentText: "Email envoyé",

    // WAITING REQUESTS
    waitingRequestTitle: "Missions proposées",
    waitingRequestDescription: nbWaitingRequests => `${nbWaitingRequests > 1 ? "demandes " : "demande "}en attente`,
    noWaitingRequests: "Aucune demande en cours",

    // CURRENT MISSIONS
    currentMissionsTitle: "Missions confiées",
    currentMissionsDescription: nbCurrentMissions =>
        `${nbCurrentMissions > 1 ? "ordres " : "ordre "}de mission à télécharger`,
    noCurrentMissions: "Aucun ordre de mission à télécharger",

    // DATE INTERVENTION
    currentInterventionDate: nbDateIntervention =>
        `${nbDateIntervention > 1 ? "dates " : "date "}de 1ère intervention à saisir`,
    noInterventionDate: "Aucune date de 1ère intervention à saisir",

    // MISSIONS
    invoicesTitle: "Factures",
    invoicesDescription: nbWaitingInvoices => `${nbWaitingInvoices > 1 ? "factures " : "facture "} en attente`,
    noInvoices: "Aucune facture en attente",

    // CREATE MISSION
    newMissionTitle: "Saisie d'une mission",
    newMissionDescription: "Accéder au formulaire de saisie d'une mission",

    // CERTIFICATE
    certificateText1:
        "Afin de s’assurer de la bonne conformité des documents réglementaires obligatoires de ses prestataires, DOMISERVE a choisi la plateforme « e-Attestations », qui est totalement gratuite pour les prestataires référencés chez DOMISERVE.",
    certificateText2:
        "Aussi, le prestataire s’engage à mettre à disposition de DOMISERVE par l’intermédiaire de la plateforme e-Attestations les documents de vigilance, attestation d’assurance, agrément, autorisation … et cela à chaque sollicitation.",
    certificateText3:
        "La mise à jour des documents vous permettra de pouvoir à nouveau accepter les missions proposées.",

    // PAYMENT INFORMATIONS
    paymentInformationsLocationText: "Domiciliation bancaire",
    paymentInformationsBicText: "BIC",
    paymentInformationsIbanText: "IBAN",
    paymentInformationExplanationText1:
        "Le règlement des factures sera effectué par virement sur le compte rattaché au RIB fourni sur la plateforme en ligne sécurisée mise à disposition, gratuitement, à l’adresse suivante : ",
    paymentInformationExplanationText2:
        "Aussi, le prestataire s’engage à mettre à disposition de DOMISERVE par l’intermédiaire de la plateforme e-Attestations son RIB et cela à chaque sollicitation ou modification.",

    // MAIN INFORMATIONS
    mainInformationsConfirmSetAdressText:
        "Vous avez effectué des modifications sur cette adresse. Si vous changez de type d'adresse vous perdrez ces modifications. Souhaitez vous continuer ?",

    // USER PROFILE AUTHENTICATION INFORMATION
    profileAuthenticationInformationsChangingPasswordSuccess: "Votre mot de passe a été modifié avec succès.",
    profileAuthenticationInformationsPasswordPopup:
        "Votre mot de passe doit valider les règles de sécurité listées ci-dessous.",

    // ERROR REQUEST
    eAttestationsDeclarantUrlRequestError:
        "Erreur lors de la récupération de l’adresse de l’espace déclarant d'e-Attestations",

    // WAITING REQUESTS
    waitingRequestsText: "Retrouvez ici les demandes envoyées par Domiserve",
    waitingRequestFolderNumber: "Numéro Dossier",
    waitingRequestServiceType: "Type de prestation",
    waitingRequestServiceBeginDate: "Date de début de prestation",
    waitingRequestPostalCodeTown: "Code Postal - Ville",
    waitingRequestInterventionZone: "Zone d'intervention",
    waitingRequestAnswerDate: "Réponse avant le",
    waitingRequestInterventionZoneFilter: "Zone d'intervention",
    waitingRequestsPricesListText: "Retrouvez ici vos grilles tarifaires",
    waitingRequestTotalAmount: "Montant total prévisionnel de la mission (prestation et frais compris)",

    waitingRequestsNumber: "Numéro de demande",

    waitingRequestAgreementText1: "Un lien vers un document récapitulatif vous sera envoyé par mail.",
    waitingRequestAgreementText2:
        "Il vous appartiendra de le télécharger dans les meilleurs délais afin de confirmer votre accord.",

    waitingRequestRefusalText:
        "Nous vous remercions de sélectionner le motif de refus dans la liste déroulante afin d'améliorer la qualité de nos échanges.",

    waitingRequestProlongementText1: hours =>
        "Un délai supplémentaire de " + hours + "H vous sera accordé pour vous permettre de nous faire un retour.",

    waitingRequestProlongementText2: "Nous vous remercions de nous faire parvenir votre réponse dans ce délai.",

    waitingRequestAttributionParagraph: "S'attribuer la demande",
    waitingRequestAttributedParagraph: (login, name) => `Attribution en cours par ${login} (${name})`,
    waitingRequestAgreementButton: "Accord",
    waitingRequestRefusalButton: "Refus",
    waitingRequestProlongementButton: "Prolongation",

    waitingRequestProlongementButtonTooltip: hours => "Prolonger de " + hours + " heures le délai de réponse",

    requestStatus: "Statut de la demande",

    serviceRequestDetailsComplementaryInformations: "Informations complémentaires",
    serviceRequestDetailsMissionAcceptationBlockedText1: "Vos attestations ne sont pas à jour, consultez la page ",
    serviceRequestDetailsMissionAcceptationBlockedText2: " pour plus d'informations.",
    serviceRequestDetailsAttributedParagraph: (login, name) => `Attribuée à ${login} ${name ? `(${name})` : ""}`,

    serviceRequestDetailsMissingButtonLabel:
        "Le délai est dépassé vous ne pouvez plus accepter / refuser ou prolonger votre demande de prestation",

    serviceRequestDetailsFileLabel: (serviceRequestNumber, serviceRequestType) =>
        "Dossier N° " + serviceRequestNumber + " - " + serviceRequestType,
    serviceRequestDetailsHistoryTitle: "Historique du dossier",
    serviceRequestDetailsHistorySentDate: sentDate => `Demande envoyée le ${sentDate}`,
    serviceRequestDetailsHistoryResponseBefore: date => `Réponse avant le ${date}`,
    serviceRequestDetailsHistoryStatusDate: (status, date) => `${status} le  ${date}`,
    serviceRequestRefusalCauseLabel: "Cause du refus",
    serviceRequestDetailsStatusLabelAccepted: date => `Demande acceptée${date ? ` le ${date}` : ""}`,
    serviceRequestDetailsStatusLabelRefused: (refusalCause, date) =>
        `Demande refusée (${refusalCause})${date ? ` le ${date}` : ""}`,
    serviceRequestDetailsStatusLabelCanceled: date => `Demande annulée par Domiserve${date ? ` le ${date}` : ""} `,
    serviceRequestDetailsStatusLabelCanceledWithCause: (date, refusalCause) =>
        `Demande annulée (${refusalCause})${date ? ` le ${date}` : ""}`,

    serviceRequestDetailsChildrenCareText: (date, beginHour, endHour) => `Le ${date} de ${beginHour} à ${endHour}`,
    serviceRequestDetailsDateBegin: (dateBegin, nbHours) =>
        `${!!dateBegin ? `Le  ${dateBegin} ` : ""}${nbHours !== 0 ? `pour ${nbHours} heures` : ""}`,
    serviceRequestDetailsDateBeginStandard: (dateBegin, nbHours) =>
        `${!!dateBegin ? `À compter du  ${dateBegin} ` : ""}${nbHours !== 0 ? `pour ${nbHours} heures` : ""}`,
    serviceRequestDetailsDateBeginNbHours: (dateBegin, nbHours) =>
        `${!!dateBegin ? `Le ${dateBegin} ` : ""}${nbHours !== 0 ? `pour ${nbHours}` : ""}`,
    serviceRequestDetailsDateBeginEstimate: (dateBegin, nbHours, nbMonth) =>
        `Débute le ${dateBegin} pour ${nbHours} heures par mois pendant ${nbMonth} mois`,
    serviceRequestDetailsBeneficiaryAge: age => `Age du bénéficiaire: ${age} ans`,
    serviceRequestDetailsHourSlice: (datePrestation, heureDebut, heureFin) =>
        `Le ${datePrestation} ${heureDebut && heureFin ? `de ${heureDebut} à ${heureFin}` : `à  ${heureDebut}`}`,
    serviceRequestDetailsChildrenDetailsAge: (age, dateOfBirth) => `Enfant de ${age} ans (né le ${dateOfBirth})`,

    serviceRequestDetailsTimeAt: timeAt => `A ${timeAt}`,
    serviceRequestDetailsTimeFromTo: (timeFrom, timeTo, duration) => `De ${timeFrom} à ${timeTo} (${duration})`,
    serviceRequestDetailsWithAccompaniment: "Avec accompagnant (autre que le prestataire)",
    serviceRequestDetailsWithoutAccompaniment: "Sans accompagnant (autre que le prestataire)",
    serviceRequestDetailsWithWaiting: " avec attente",
    serviceRequestDetailsWithoutWaiting: " sans attente",
    serviceRequestDetailsCommentLabel: "Commentaire",
    serviceRequestDetailsKilometers: kilometers => `Distance à parcourir globale à toute la mission : ${kilometers}km`,
    serviceRequestDetailsOutingPlace: "Lieu de sortie : ",
    serviceRequestDetailsShoppingPlace: "Lieu des courses : ",
    serviceRequestDetailsGlobalHoursInformations:
        "(Les dates et horaires sont définis à titre indicatif et seront à confirmer lors de votre prise de contact avec le bénéficiaire)",
    serviceRequestDetailsAmountDetailsTitle: billingCompany =>
        `Détail du montant total prévisionnel ${
            !!billingCompany && billingCompany === BillinCompany.DOMISERVE ? "(TTC)" : "(HT)"
        }`,
    serviceRequestDetailsAmountHours: (duration, priceList, amount) =>
        `${duration} à ${fromNumberToEuros(priceList)} = ${fromNumberToEuros(amount)}`,
    serviceRequestDetailsAmountFixedPrice: amount => `FORFAIT à ${fromNumberToEuros(amount)}`,
    serviceRequestDetailsAmountKilometerExpenses: (kms, priceList, amount) =>
        `${kms}km à ${fromNumberToEuros(priceList)} = ${fromNumberToEuros(amount)}`,

    exportExcelLabel: "EXPORT EXCEL",

    // INVOICES TEXTS
    invoicesSceneDescription: "Retrouvez ici vos factures",
    invoicesSceneFormPanelFilterTitle: "Filtres",
    invoicesSceneInvoiceNumberPlaceholder: "Numéro de facture",
    invoicesScenePeriodLabel: "Période",
    invoicesSceneStatusPlaceholder: "Statut",
    invoicesSceneMissionNumberPlaceholder: "Numéro de mission",
    invoicesSceneInvoicingReceptionDateLabel: "Date de réception facturation",
    invoicesSceneInvoicingFacturationDateLabel: "Date de comptabilisation",
    invoicesSceneInvoicingFacturationDateBeginingLabel: "Début comptabilisation",
    invoicesSceneInvoicingFacturationDateEndLabel: "Fin comptabilisation",
    invoicesSceneInvoicingFacturationDateFrom: "Comptabilisation du : ",
    invoicesSceneInvoicingFacturationDateTo: "au",
    invoicesSceneAmountLabel: "Montant",
    invoicesSceneInterventionZoneLabel: "Zone d'intervention",
    invoicesSceneMonthConcernedPlaceholder: "Mois concerné",
    invoicesSceneContractLabel: "Dispositif",

    invoiceDetails: "",
    invoiceDetailsLabel: (invoiceNumber, monthConcerned) => `Facture N° ${invoiceNumber} pour ${monthConcerned}`,
    invoiceDetailsInterventionZoneLabel: "Zone d'intervention : ",
    invoiceDetailsInvoiceReceptionDateLabel: "Date de réception : ",
    invoiceDetailsInvoiceFacturationDateLabel: "Date de comptabilisation : ",
    invoiceDetailsMissionNumberLabel: "Mission associée : ",
    invoiceDetailsStatusLabel: "Statut : ",
    invoiceDetailsAmountLabel: "Montant : ",
    invoiceDetailsPrestationTypeLabel: "Type de prestation : ",
    invoiceDetailsTotalHoursBilled: "Nombre d'heures facturées : ",
    invoiceDetailsContractLabel: "Dispositif : ",
    invoiceDetailsReclamationNumberLabel: "Incident associé : ",
    invoiceDetailsReclamationCategoryLabel: "Catégorie de l'incident : ",
    invoiceDetailsCommentTitleLabel: (login, date) => `${login} - Le ${date}`,
    invoiceDetailsCommentsHeader: "Suivi des actions",
    invoiceDetailsAttachmentsHeader: "Liste des pièces",
    invoiceDetailsAttachmentSendAtLabel: date => ` envoyée le ${date}`,
    invoiceFromDetailsCommentFieldPlaceholder: "Votre commentaire",
    invoiceFromDetailsImportFilePlaceholder: "Ajouter une pièce (.pdf, .docx, .doc, .xls, .xlsx)",
    invoiceFromDetailsButtonLabel: "Envoyer à Domiserve",

    invoiceNotificationsHeaderExportError: "Erreur lors de la génération de l'export excel",

    // MISSIONS TEXTS
    missionAddingBeneficiaryMissionContentLabel: "Je saisis une mission :",
    missionAddingBeneficiaryChooseAnOtherBeneficiaryLabel: "Choisir un autre bénéficiaire ?",
    missionAddingBeneficiaryNotFoundedBeneficiaryLabel: "Bénéficiaire non trouvé ?",
    missionAddingCannotAddNewBeneficiaryLabel:
        "Si vous ne trouvez pas le bénéficiaire, veuillez l'inviter à contacter Domiserve pour une première mise en relation.",
    missionAddingBeneficiaryIdentityLastNameLabel: "Nom *",
    missionAddingBeneficiaryIdentityFirstNameLabel: "Prénom *",
    missionAddingBeneficiaryIdentityBirthDateLabel: "Date de naissance *",
    missionAddingBeneficiaryContactMainPhoneNumberLabel: "Tél. Fixe ",
    missionAddingBeneficiaryContactSecondaryPhoneNumberLabel: "Tél. Mobile ",
    missionAddingBeneficiaryContactEmailLabel: "E-mail",

    missionAddingDetailsErrorMessageAdding: "Erreur lors de l'ajout.",
    missionAddingDetailsErrorMessageSameDate: "Une mission prévue à la même heure a déjà été ajoutée.",
    missionAddingDetailsErrorRequest: "Erreur lors de l'enregistrement de la mission.",
    missionAddingDetailsSuccessMessage: "Nous avons bien pris en compte ces informations.",
    missionAddingDetailsSummaryPanelTitle: "Récapitulatif",
    missionAddingDetailsSummaryPanelIntro: (missionType, beneficiaryFullName) =>
        `Mission ${missionType} pour le bénéficiaire ${beneficiaryFullName} : `,
    missionAddingDetailsSummaryConfirmation: "Vous pouvez suivre le traitement de votre demande dans ",
    missionAddingDetailsSummaryConfirmationNewBeneficiary:
        "Nous avons bien pris en compte ces informations. Une fois votre demande traitée, vous retrouverez la mission correspondante dans le menu Mission en cours",

    missionAddingDetailsDateAndHourPrestationLabel: "Date et heure de prestation ",
    missionAddingDetailsInterventionZonePrestationLabel: "Zone d'intervention ",
    missionAddingDetailsFormPanelTitle: "Motif d'accompagnement hors du domicile",
    missionAddingDetailsUtilisationProjectLabel: "Projet d'utilisation ",
    missionAddingDetailsServiceRequestedNatureLabel: "Nature de la prestation souhaitée ",
    missionAddingDetailsTitle: "Détails de la mission",

    missionAddBeneficiarySearchCondition:
        "Merci de rechercher le bénéficiaire dans le champ ci-dessous ou de remplir l'ensemble des informations relatives à ce bénéficiaire.",
    missionAddBeneficiarySearchInformation:
        "En cliquant sur 'Valider', un formulaire vous permettra de renseigner le détail de la sortie.",

    missionDetailSearchCondition1:
        "Si vous devez saisir plusieurs sorties pour ce même bénéficiaire, cliquez sur 'Ajouter'.",
    missionDetailSearchCondition2:
        "A la fin de la saisie, merci de cliquer sur 'Transmettre à Domiserve' pour nous communiquer ces informations.",

    missionsSceneCurrentMissionsHeader: "Retrouvez ici toutes vos missions en cours",
    missionsSceneHistoryMissionsHeader: "Retrouvez ici l'historique de vos missions",

    missionsSearchPanelServiceRequestNumberPlaceholder: "Numéro du dossier",
    missionsSearchPanelPrestationTypePlaceholder: "Type de prestation",
    missionsSearchPanelBeneficiaryPlaceholder: "Bénéficiaire",
    missionsSearchBeneficiaryPlaceholder: "Nom, Prénom du bénéficiaire :",
    missionsSearchBeneficiaryDefaultMissionType: "Sortir +",
    missionsSearchPanelInterventionZonePlaceholder: "Zone d'intervention",
    missionsSearchPanelMissionStatusPlaceholder: "Statut de la mission",
    missionSearchBeneficiaryPhoneRequired: "Au moins un champ téléphone doit être renseigné.",

    missionsTableHeaderServiceRequestNumber: "Numéro",
    missionsTableHeaderPrestationType: "Type de prestation",
    missionsTableHeaderODMSendingDate: "Date d'envoi d'ODM",
    missionsTableHeaderTotalAmount: "Montant total prévisionnel de la mission (prestation et frais compris)",
    missionsTableHeaderBeneficiary: "Bénéficiaire",
    missionsTableHeaderPostalCodeTown: "Code postal - Ville",
    missionsTableHeaderInterventionZone: "Zone d'intervention",
    missionsTableHeaderMissionStatus: "Statut",
    missionsTableHeaderActionDownloadODM: "Télécharger l'ordre de mission",
    missionsTableHeaderActionShowDetails: "Voir les détails",

    missionsNotificationsHeaderUpdateSuccess: "Informations transmises à Domiserve",
    missionsNotificationsContentUpdateSuccess: (missionNumber, status) =>
        `Une fois toutes les informations transmises, la mission ${missionNumber} sera visible au statut "${status}"`,
    missionsNotificationsHeaderDetailsError: "Erreur lors de la récupération des détails de la mission",
    missionsNotificationsHeaderDownloadODMError: "Erreur lors du téléchargement de l'ordre de mission",
    missionsNotificationsHeaderDownloadAnnulationError: "Erreur lors du téléchargement de l'annulation de mission",
    missionsNotificationsHeaderExportError: "Erreur lors de la génération de l'export excel",
    missionsNotificationsHeaderUpdateError: "Erreur lors de la mise à jour de la mission",
    missionsContactDateValidationMessage:
        "La date de prise de contact doit être postérieure à la date de l'envoi de l'ordre de mission",
    missionsMeetingDateValidationMessage: "La date de rendez-vous doit être postérieure à la date de prise de contact",

    missionsDetailsHeader: (serviceRequestNumber, prestationType) =>
        `Dossier N° ${serviceRequestNumber} - ${prestationType}`,
    missionsDetailsFilesToDownloadHeader: "Documents à télécharger",
    missionsDetailsFilesToDownloadODM: "Ordre de mission",
    missionsDetailsFilesToDownloadAnnulation: partielle =>
        `Annulation${partielle === "Partielle" ? " partielle" : ""} de mission`,
    missionsDetailsFilesToDownloadBCMReportTemplate: "Modèle de compte rendu",
    missionsDetailsFilesToDownloadBCMReportSynthesisTemplate: "Modèle de synthèse de compte rendu",
    missionsDetailsMissionHistoryHeader: "Historique du dossier",
    missionsDetailsMissionHistoryMissionAcceptedDate: date => `Mission acceptée le ${date}`,
    missionsDetailsMissionHistoryODMSentDate: date => `Ordre de mission envoyé le ${date}`,
    missionsDetailsMissionHistoryODMDownloadedDate: date => `Ordre de mission téléchargé le ${date}`,
    missionsDetailsCancelMissionButtonLabel: "Signaler une annulation",
    missionsDetailsInformationToSendHeader: "Informations à transmettre",
    missionsDetailsInformationToSendInitialContactDateLabel: "Date de prise de contact avec le bénéficiaire",
    missionsDetailsInformationToSendLateInitialContactDateCause: "Motif de prise de contact tardive",
    missionsDetailsInformationToSendLateInitialContactDatePopuContent:
        "La date de prise de contact est supérieure de 8 jours à la réception de cette demande, merci de nous précisez le motif.",
    missionsDetailsInformationToSendFirstMeetingDateLabel: "Date de rendez-vous",
    missionsDetailsInformationToSendSendFileLabel: "Synthèse du compte rendu",
    missionsDetailsInformationToSendSendFilePlaceholder: "Sélectionner un fichier (.pdf, .doc, .docx, .xls, .xlsx)",
    missionsDetailsInformationToSendLateMeetingCauseLabel: "Motif de rendez-vous tardif",
    missionsDetailsInformationToSendLateMeetingCausePopupContent:
        "La date de rendez-vous fixée est supérieure à 1 mois suite à la réception de cette demande, merci de renseigner le motif.",
    missionsDetailsInformationToSendSelectCausePlaceholder: "Sélectionner un motif",
    missionsDetailsInformationToSendFirstPlannedMissionDateLabel: "Date de première prestation planifiée",
    missionsDetailsInformationToSendFirstPlannedMissionDatePopupContent:
        "Attention, après concertation avec le bénéficiaire, vous devez obligatoirement saisir la date de 1ere intervention planifiée. Sans cette information, la prestation ne pourra avoir lieu et par conséquent la facturation sera impossible",
    missionsDetailsInformationToSendButtonLabel: "Transmettre les informations",
    missionsDetailsFirstMissionDatePlannedLabel: "Date de 1ère prestation planifiée le ",
    missionsDetailsInitialContactDateLabel: " Prise de contact avec le bénéficiaire le ",
    missionsDetailsInitialFirstMeetingDateLabel: "Rendez-vous le ",
    missionsDetailsSentSynthesisDateLabel: "Synthèse envoyée le ",
    missionsDetailsSynthesisUpdatedDateLabel: "Synthèse mise à jour le ",
    missionsDetailsMissionCancellationAskedDate: "Demande d'annulation envoyée le ",
    missionsDetailsDateAnnulationEnvoyee: partielle =>
        `Document d'annulation${partielle === "Partielle" ? " partielle" : ""} envoyé le `,
    missionsDetailsDateTelechargementAnnulation: partielle =>
        `Document d'annulation${partielle === "Partielle" ? " partielle" : ""} téléchargé le `,

    missionsCancelMissionModalStartedMissionLabel: "Cette mission a-t-elle débuté ? *",
    missionsCancelMissionModalNbHoursAlreadyDoneLabel: "Précisez le nombre d'heures effectuées *",
    missionsCancelMissionModalCommentaryLabel: "Commentaire *",

    // New mission input fastt
    newMissionInputFasttAgencyNameLabel: "Nom de l'agence d'intérim",
    newMissionInputFasttMissionContractLabel: "Contrat de mission de l'intérimaire",
    newMissionInputFasttMissionContractPlaceholder: "Contrat de mission (.pdf)",
    newMissionInputFasttMissionStartDateLabel: "Date de début de mission",
    newMissionInputFasttMissionEndDateLabel: "Date de fin de mission",
    newMissionInputFasttSelectedChildrenLabel: "Enfants",
    newMissionInputFasttInterventionZoneLabel: "Crèche concernée",
    newMissionInputFasttCommentaryLabel: "Commentaire",
    newMissionInputFasttButtonLabel: "Transmettre à DOMISERVE",

    newMissionInputFasttSuccesNotificationLabel:
        "Nous avons bien pris en compte ces informations. Nous vous tiendrons informé de l’avancement de votre demande",
    newMissionInputFasttDateValidationError: "Doit être renseigné si le contrat n'est pas envoyé",

    newMissionInputFasttConfirmModalBeneficiaryLabel: "Saisie d'une mission de garde en crèche pour le bénéficiaire ",
    newMissionInputFasttConfirmModalAgencyName: "Nom de l'agence du bénéficiaire:",
    newMissionInputFasttConfirmModalStartDateLabel: "Date de début de mission:",
    newMissionInputFasttConfirmModalEndDateLabel: "Date de fin de mission:",
    newMissionInputFasttConfirmModalInterventionZoneLabel: "Crèche concernée:",
    newMissionInputFasttConfirmModalCommentaryLabel: "Commentaire:",
    newMissionInputFasttConfirmCareneedLabel: "Besoin de garde:",

    // PRICE LIST
    pricesListTableType: "Type",
    pricesListTableTitle: "Prestation",
    pricesListTableMajoration: "Type de tarif",
    pricesListTableTaux: "Tarif (HT)",
    pricesListTableTva: "TVA",
    pricesListTableLineCategorieDGE: (categorie, articleLibelle) =>
        `${categorie ? categorie + " : " : ""}${articleLibelle}`,
    pricesListTableLinePrice: (price, unitePrestation) => `${price} € par ${unitePrestation}`,
    pricesListTableLineTva: tva => `${tva} %`,

    // Update BCM FILE
    updateBcmFileShowFormLabel: "Mettre à jour le document de synthèse",
    updateBcmFileSendFormLabel: "Envoyer",

    // RESET PASSWORD / LOGIN
    loginLabel: "Identifiant",
    oldPasswordLabel: "Ancien mot de passe *",
    validateButtonLabel: "Valider",
    resetPasswordValidateUpdate: "Valider le changement",
    newPasswordLabel: "Nouveau mot de passe *",
    confirmNewPasswordLabel: "Confirmation nouveau mot de passe *",
}

let texts = {...defaultTexts}

export function updateGlobalTexts(newTexts) {
    texts = Object.assign(texts, newTexts)
}

export default texts
