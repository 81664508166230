import {combineReducers} from "redux"
import details, * as prestadomDetails from "./details/detailsReducer"
import info, * as userInfo from "./info/userInfo"

/**
 * User reducer.
 */
const prestadomUser = combineReducers({
    details,
    info,
})

export default prestadomUser

// OPERATIONS ON DETAILS
/**
 * Get user details from the store.
 */
export const getUserDetails = state => prestadomDetails.getDetails(state.details)

/**
 * Get user profile from the store.
 */
export const getUserProfile = state => prestadomDetails.getProfile(state.details)

/**
 * Get user info from store
 */
export const getUserInfo = state => userInfo.getUserInfo(state.info)
