import {combineReducers} from "redux"
import user, * as fromUser from "../../shared-components/store/sessionStorage/user/userReducer"
import prestadomUser, * as fromPrestadomUser from "../reducers/prestadom/user/userReducer"
import interventionZones, * as fromInterventionZones from "../reducers/prestadom/interventionZones/interventionZonesReducer"

/**
 * Part of the store to stock data which will be saved in local storage.
 */
const sessionStorage = combineReducers({
    interventionZones,
    prestadomUser,
    user,
})

export default sessionStorage

// USER OPERATIONS
/**
 * Get the user token from the store.
 */
export const getUserToken = state => fromUser.getUserToken(state.user)

/**
 * Get the expiration date.
 */
export const getExpirationDate = state => fromUser.getExpirationDate(state.user)
/**
 * Get user permissions from the store.
 */
export const getUserPermissions = state => fromUser.getUserPermissions(state.user)
/**
 * Get user identity from the store.
 */
export const getUserDetails = state => fromPrestadomUser.getUserDetails(state.prestadomUser)

/**
 * Get user profile from the store.
 */
export const getUserProfile = state => fromPrestadomUser.getUserProfile(state.prestadomUser)

/**
 * Get User info from store
 */
export const getUserInfo = state => fromPrestadomUser.getUserInfo(state.prestadomUser)

/**
 * Get user bypassed authentication from the store.
 */
export const getBypassedAuthentication = state => fromUser.getBypassedAuthentication(state.user)

// InterventionZone
export const getInterventionZones = state => fromInterventionZones.getInterventionZones(state.interventionZones)
