import React from "react"
import {PagesArchitecture} from "../constants/page/pagesArchitecture"
import {Page} from "../constants/page/pages"
import {AREA_TYPE} from "../constants/areaType"
import {RECOVERY_MAIL_TYPE, RECOVERY_PASSWORD_TYPE} from "../constants/recoveryType"
import history from "../history"
import {PRESTADOM_URL} from "../shared-components/constants/links"
import {
    getChangePasswordErrorMessage,
    isFetchingChangePassword,
} from "../store/selectors/temporaries/changePasswordSelectors"
import {
    getResetPasswordScore,
    getResetPasswordDisplaySuccessMessage,
} from "../store/selectors/temporaries/resetPasswordSelectors"
import {CommonRequestKeys} from "../shared-components/common/constants/requests"

const HomeScene = React.lazy(() => import("../scenes/Home/homeContainer").catch(() => window.location.reload()))

export const AsyncHomeScene = () => <HomeScene />

// ASYNC LAYOUTS
const BreadcrumbLayout = React.lazy(() => import("./breadcrumbLayout").catch(() => window.location.reload()))

export const AsyncBreadcrumbLayout = props => (
    <BreadcrumbLayout
        actualPage={props.actualPage}
        isUserConnected={props.isUserConnected}
        onProfileClick={() => history.push(PagesArchitecture[Page.PROFILE].route)}
        pageName={props.actualPage}
    />
)

// ASYNC SCENES
const UnauthorizedScene = React.lazy(() =>
    import("../shared-components/scenes/Unauthorized/unauthorizedContainer").catch(() => window.location.reload()),
)

export const AsyncUnauthorizedScene = () => (
    <UnauthorizedScene
        onHomeClick={() => history.push(PagesArchitecture[Page.HOME].route)}
        unauthorizedPage={Page.UNAUTHORIZED}
    />
)

const NotFoundScene = React.lazy(() =>
    import("../shared-components/scenes/NotFound/notFoundContainer").catch(() => window.location.reload()),
)

export const AsyncNotFoundScene = () => (
    <NotFoundScene notFoundPage={Page.NOT_FOUND} onHomeClick={() => history.push(PagesArchitecture[Page.HOME].route)} />
)

const TechnicalErrorScene = React.lazy(() =>
    import("../shared-components/components/ErrorBoundary/errorBoundary").catch(() => window.location.reload()),
)

export const AsyncTechnicalErrorScene = () => (
    <TechnicalErrorScene
        page={Page.TECHNICAL_ERROR}
        redirectToHomePage={() => history.push(PagesArchitecture[Page.HOME].route)}
    />
)

const LegalNoticeScene = React.lazy(() =>
    import("../shared-components/scenes/LegalNotice/legalNoticeContainer").catch(() => window.location.reload()),
)

export const AsyncLegalNoticeScene = () => (
    <LegalNoticeScene
        areaType={AREA_TYPE}
        legalNoticePage={Page.LEGAL_NOTICE}
        urlDomiserve={PRESTADOM_URL}
        withCGS={false}
    />
)

const RecoverLoginWithPasswordScene = React.lazy(() =>
    import("../scenes/RecoverLoginPassword/recoverLoginPasswordContainer").catch(() => window.location.reload()),
)

export const AsyncRecoverLoginWithPasswordScene = () => (
    <RecoverLoginWithPasswordScene recoveryType={RECOVERY_PASSWORD_TYPE} />
)

const RecoverLoginWithMailScene = React.lazy(() =>
    import("../scenes/RecoverLoginPassword/recoverLoginPasswordContainer").catch(() => window.location.reload()),
)

export const AsyncRecoverLoginWithMailScene = () => <RecoverLoginWithMailScene recoveryType={RECOVERY_MAIL_TYPE} />

const ResetPasswordScene = React.lazy(() =>
    import("../scenes/ResetPassword/resetPasswordContainer").catch(() => window.location.reload()),
)

export const AsyncResetPasswordScene = () => (
    <ResetPasswordScene
        areaType={AREA_TYPE}
        errorMessage={getChangePasswordErrorMessage}
        getResetPasswordDisplaySuccessMessage={getResetPasswordDisplaySuccessMessage}
        getResetPasswordScore={getResetPasswordScore}
        isFetching={isFetchingChangePassword}
        loginRoute={PagesArchitecture[Page.LOGIN].route}
        passwordRequestKeyName={CommonRequestKeys.CHANGE_PASSWORD}
        resetPasswordPage={Page.RESET_PASSWORD}
    />
)

const UserProfileLayout = React.lazy(() => import("./userProfileLayout").catch(() => window.location.reload()))

export const AsyncUserProfileLayout = props => (
    <UserProfileLayout isUserConnected={props.isUserConnected} pageName={props.actualPage} />
)

const ContactScene = React.lazy(() =>
    import("../scenes/Contact/contactContainer").catch(() => window.location.reload()),
)

export const AsyncContactScene = () => <ContactScene />

const UserProfileMainInformationsScene = React.lazy(() =>
    import("../scenes/Profile/UserProfileMainInformations/userProfileMainInformationsContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncUserProfileMainInformationsScene = () => <UserProfileMainInformationsScene />

const UserProfileAttestationsScene = React.lazy(() =>
    import("../scenes/Profile/UserProfileAttestations/userProfileAttestationsContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncUserProfileAttestationsScene = () => <UserProfileAttestationsScene />

const UserProfilePaymentInformationsScene = React.lazy(() =>
    import("../scenes/Profile/UserProfilePaymentInformations/userProfilePaymentInformationsContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncUserProfilePaymentInformationsScene = () => <UserProfilePaymentInformationsScene />

const UserProfileAuthenticationInformationsScene = React.lazy(() =>
    import(
        "../scenes/Profile/UserProfileAuthenticationInformations/userProfileAuthenticationInformationsContainer"
    ).catch(() => window.location.reload()),
)

export const AsyncUserProfileAuthenticationInformationsScene = () => <UserProfileAuthenticationInformationsScene />

const SceneWaitingServiceRequests = React.lazy(() =>
    import("../scenes/ServiceRequests/serviceRequestsContainer").catch(() => window.location.reload()),
)

export const AsyncSceneWaitingServiceRequests = () => <SceneWaitingServiceRequests pageName={Page.WAITING_REQUESTS} />

const SceneServiceRequestHistory = React.lazy(() =>
    import("../scenes/ServiceRequests/serviceRequestsContainer").catch(() => window.location.reload()),
)

export const AsyncSceneServiceRequestHistory = () => (
    <SceneServiceRequestHistory isHistory={true} pageName={Page.REQUESTS_HISTORY} />
)

const SceneMissions = React.lazy(() =>
    import("../scenes/Missions/missionsContainer").catch(() => window.location.reload()),
)

export const AsyncSceneMissions = () => <SceneMissions isMissionsHistory={false} pageName={Page.CURRENT_MISSIONS} />

const MissionAddingLayout = React.lazy(() => import("./missionAddingLayout").catch(() => window.location.reload()))

export const AsyncMissionAddingLayout = () => <MissionAddingLayout />

const SceneNewMissionInputInit = React.lazy(() =>
    import("../scenes/NewMissionInputInit/newMissionInputInitContainer").catch(() => window.location.reload()),
)

export const AsyncSceneNewMissionInputInit = () => <SceneNewMissionInputInit />

const SceneNewMissionInputDetails = React.lazy(() =>
    import("../scenes/NewMissionInputDetails/newMissionInputDetailsContainer").catch(() => window.location.reload()),
)

export const AsyncSceneNewMissionInputDetails = () => <SceneNewMissionInputDetails />

const SceneMissionsHistory = React.lazy(() =>
    import("../scenes/Missions/missionsContainer").catch(() => window.location.reload()),
)

export const AsyncSceneMissionsHistory = () => (
    <SceneMissionsHistory isMissionsHistory={true} pageName={Page.MISSIONS_HISTORY} />
)

const SceneInvoices = React.lazy(() =>
    import("../scenes/Invoices/invoicesContainer").catch(() => window.location.reload()),
)

export const AsyncSceneInvoices = () => <SceneInvoices />

const ScenePricesList = React.lazy(() =>
    import("../scenes/PricesList/pricesList").catch(() => window.location.reload()),
)

export const AsyncScenePricesList = () => <ScenePricesList pageName={Page.PRICE_LIST} />
