import * as fromRequests from "../../../shared-components/store/requests/requestsReducer"
import {CommonRequestKeys} from "../../../shared-components/common/constants/requests"

/**
 * Verify if change password request is fetching
 */
export const isFetchingChangePassword = state =>
    fromRequests.isFetching(CommonRequestKeys.CHANGE_PASSWORD, state.requests)

/**
 * Return the change password request error message
 */
export const getChangePasswordErrorMessage = state =>
    fromRequests.getErrorMessage(CommonRequestKeys.CHANGE_PASSWORD, state.requests)
