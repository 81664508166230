import PropTypes from "prop-types"
import React, {Component, Suspense} from "react"
import {connect} from "react-redux"
import {Route, Router, Switch, withRouter} from "react-router-dom"
import {AREA_TYPE} from "../constants/areaType"
import {menuContent} from "../constants/menu/menu"
import {Page} from "../constants/page/pages"
import {PagesArchitecture} from "../constants/page/pagesArchitecture"
import {saveCookiesChoices} from "../shared-components/actions/localStorage/cookies/cookiesActions"
import {setResponsive} from "../shared-components/actions/temporaries/menu/menuAction"
import {disconnectUser, logoutUser} from "../shared-components/common/actions/user/userActions"
import CheckBrowserContainer from "../shared-components/components/CheckBrowser/checkBrowserContainer"
import CookiesNotificationContainer from "../shared-components/components/CookiesNotification/cookiesNotificationContainer"
import Footer from "../shared-components/components/Footer/footer"
import MenuContainer from "../shared-components/components/Menu/menu"
import MenuTop from "../shared-components/components/MenuTop/menuTop"
import PermissionRoute from "../shared-components/components/PermissionRoute/permissionRoute"
import {
    getCookiesChoices,
    getModalStep,
    isCookiesModalOpened,
    isCookiesUserAcceptanceExpired,
    isUserAcceptedCookies,
} from "../store/selectors/localStorage/cookiesSelectors"
import {getDynamicConfigurationValue} from "../store/selectors/localStorage/dynamicConfigurationSelectors"
import {getStyle} from "../store/selectors/localStorage/styleSelectors"
import {getTexts} from "../store/selectors/localStorage/textSelector"
import {
    getExpirationDate,
    getUserFullname,
    getUserPermissions,
    getUserToken,
    isUserConnected,
} from "../store/selectors/sessionStorage/userSelectors"
import {isMenuResponsive} from "../store/selectors/temporaries/menuSelectors"
import {getActualPage} from "../store/selectors/temporaries/pageSelectors"
import {updateGlobalTexts} from "../styles/globalText"
import {AsyncBreadcrumbLayout, AsyncHomeScene} from "./asyncScenes"

import LOGO from "../img/logo.png"
import prestadomStyle from "../styles/prestadomStyle"

import {getParameter} from "../actions/parameters/parametersAction"
import {defaultParameters} from "../constants/parameters/parameters"
import "./mainLayout.scss"
import {MatomoTrackPageView} from "./matomoTrackPageView"

class MainLayout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            eAttestationsDeclarantUrl: "",
        }
    }
    componentDidMount = () => {
        this.props
            .getParam(defaultParameters.eAttestationsDeclarantUrl)
            .then(payload => this.setState({eAttestationsDeclarantUrl: payload.valeur}))
    }

    getBreadcrumbLayout = () => (
        <AsyncBreadcrumbLayout actualPage={this.props.actualPage} isUserConnected={this.props.isUserConnected} />
    )

    onDisconnectClick = () => {
        this.props.logoutUser()
        this.props.disconnectUser()
    }

    render() {
        const {
            actualPage,
            features,
            history,
            isMenuResponsive,
            isUserConnected,
            onConnectClick,
            onContactClick,
            onCookiesClick,
            onHomeClick,
            onLegalClick,
            onProfileClick,
            onVisualisationChoiceClick,
            setMenuResponsive,
            userPermissions,
            username,
        } = this.props

        const defaultRouteExternalSelectors = {
            getStyle,
            getTexts,
            getDynamicConfigurationValue,
            getExpirationDate,
            updateGlobalTexts,
            customStyle: prestadomStyle,
        }

        return (
            <Router history={history}>
                <div
                    className={
                        "main-layout" +
                        (process.env.NODE_ENV === "production" && !process.env.REACT_APP_PROD_MODE
                            ? " main-layout-bordered"
                            : "")
                    }
                >
                    <MatomoTrackPageView />
                    <MenuTop
                        areaType={AREA_TYPE}
                        canAccessUserProfile={true}
                        isConnected={isUserConnected}
                        onConnectClick={onConnectClick}
                        onDisconnectClick={this.onDisconnectClick}
                        onProfileClick={onProfileClick}
                        onVisualisationChoiceClick={onVisualisationChoiceClick}
                        username={username}
                    />
                    <div
                        className={
                            (isMenuResponsive ? "main-page-responsive" : "main-page-not-responsive") + " main-page"
                        }
                    >
                        <div className="menu-container">
                            <MenuContainer
                                areaType={AREA_TYPE}
                                features={features}
                                isConnected={isUserConnected}
                                isMenuResponsive={isMenuResponsive}
                                menuContent={menuContent(
                                    actualPage,
                                    history,
                                    features,
                                    this.state.eAttestationsDeclarantUrl,
                                )}
                                onConnectClick={onConnectClick}
                                onDisconnectClick={this.onDisconnectClick}
                                onHomeClick={onHomeClick}
                                onProfileClick={onProfileClick}
                                setMenuResponsive={setMenuResponsive}
                                staticLogo={LOGO}
                                title={AREA_TYPE}
                                userPermissions={userPermissions}
                            />
                        </div>
                        <CheckBrowserContainer userAgent={window.navigator.userAgent} />
                        <div className="main-content">
                            <Suspense fallback={<div />}>
                                <Switch>
                                    {/* Redirect / to Home */}
                                    <PermissionRoute
                                        component={AsyncHomeScene}
                                        exact
                                        getUserPermissions={getUserPermissions}
                                        getUserToken={getUserToken}
                                        loginRoute={PagesArchitecture[Page.LOGIN].route}
                                        page={Page.HOME}
                                        pagesArchitecture={PagesArchitecture}
                                        path="/"
                                        unauthorizedRoute={PagesArchitecture[Page.UNAUTHORIZED].route}
                                        {...defaultRouteExternalSelectors}
                                    />

                                    {/* Breadcrumb layout */}
                                    <Route component={this.getBreadcrumbLayout} path="" />
                                </Switch>
                            </Suspense>
                        </div>
                    </div>
                    <CookiesNotificationContainer
                        getCookiesChoices={getCookiesChoices}
                        getModalStep={getModalStep}
                        isCookiesModalOpened={isCookiesModalOpened}
                        isCookiesUserAcceptanceExpired={isCookiesUserAcceptanceExpired}
                        isUserAcceptedCookies={isUserAcceptedCookies}
                    />
                    <Footer
                        onContactClick={onContactClick}
                        onCookiesClick={onCookiesClick}
                        onLegalClick={onLegalClick}
                    />
                </div>
            </Router>
        )
    }
}

MainLayout.propsTypes = {
    actualPage: PropTypes.string.isRequired,
    getParam: PropTypes.func.isRequired,
    isMenuResponsive: PropTypes.bool.isRequired,
    onConnectClick: PropTypes.func.isRequired,
    onCookiesClick: PropTypes.func.isRequired,
    onContactClick: PropTypes.func.isRequired,
    onDisconnectClick: PropTypes.func.isRequired,
    onHomeClick: PropTypes.func.isRequired,
    onProfileClick: PropTypes.func.isRequired,
    onLegalClick: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    let actualPage = getActualPage(state)
    return {
        actualPage: actualPage,
        isMenuResponsive: isMenuResponsive(state),
        isUserConnected: isUserConnected(state) && actualPage !== Page.BYPASS_LOGIN,
        username: getUserFullname(state),
        userPermissions: getUserPermissions(state),
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    // Display cookies modal
    onCookiesClick: () => dispatch(saveCookiesChoices({isModalOpened: true, step: "CONFIGURATION"})),

    // Go to the contact page.
    onContactClick: () => props.history.push(PagesArchitecture[Page.CONTACT].route),

    // Go to the connection page.
    onConnectClick: () => props.history.push(PagesArchitecture[Page.LOGIN].route),

    // Disconnect the current user.
    disconnectUser: () => dispatch(disconnectUser()),

    // Get parameter from DB
    getParam: paramId => dispatch(getParameter(paramId)),

    logoutUser: () => dispatch(logoutUser()),

    // Go to the home page.
    onHomeClick: () => {
        props.history.push(PagesArchitecture[Page.HOME].route)
    },

    //Go to user profile page
    onProfileClick: () => props.history.push(PagesArchitecture[Page.USER_PROFILE_MAIN_INFORMATIONS].route),

    // Go to the legal mentions page.
    onLegalClick: () => props.history.push(PagesArchitecture[Page.LEGAL_NOTICE].route),

    setMenuResponsive: isResponsive => {
        dispatch(setResponsive(isResponsive))
    },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayout))
