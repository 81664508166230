import {
    SAVE_MISSION_ADDING_BENEFICIARY,
    RESET_MISSION_ADDING_BENEFICIARY,
} from "../../../actions/localStorage/missionAddingBeneficiary/missionAddingBeneficiaryActionTypes"

const missionAddingBeneficiary = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_MISSION_ADDING_BENEFICIARY:
            return action.values
        case RESET_MISSION_ADDING_BENEFICIARY:
            return {}
        default:
            return state
    }
}

export default missionAddingBeneficiary

export const getMissionAddingBeneficiary = state => state
