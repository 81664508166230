import {REQUEST_SUCCESS} from "../../../../../shared-components/store/requests/requestsReducer"
import {CommonRequestKeys} from "../../../../../shared-components/common/constants/requests"

const info = (state = {}, action = {}) => {
    if (action.type === REQUEST_SUCCESS && action.requestKeyName === CommonRequestKeys.USER_INFO) {
        return action.payload
    } else {
        return state
    }
}

export default info

/**
 * Get user info state
 */
export const getUserInfo = state => state
