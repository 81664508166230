import {createDropdown, createItem} from "../../shared-components/common/utils/menu/menu"
import {Page} from "../page/pages"
import {PagesArchitecture} from "../page/pagesArchitecture"

/**
 * Return the menu
 * @param actualPage page where the user is
 * @param history    history function for navigation
 * @param features   enabled application features
 */
export const menuContent = (actualPage, history, features, eattestationUrl) => [
    createDropdown("MISSIONS PROPOSEES", true, [
        createItem(actualPage, history, features, Page.WAITING_REQUESTS, PagesArchitecture),
        createItem(actualPage, history, features, Page.REQUESTS_HISTORY, PagesArchitecture),
    ]),

    createDropdown("MISSIONS CONFIEES", true, [
        createItem(actualPage, history, features, Page.NEW_MISSION_INPUT_INIT, PagesArchitecture),
        createItem(actualPage, history, features, Page.CURRENT_MISSIONS, PagesArchitecture),
        createItem(actualPage, history, features, Page.MISSIONS_HISTORY, PagesArchitecture),
    ]),

    createItem(actualPage, history, features, Page.INVOICES, PagesArchitecture),
    createItem(actualPage, history, features, Page.PRICE_LIST, PagesArchitecture),
    createItem(actualPage, history, features, Page.LOGIN, PagesArchitecture),
    createItem(actualPage, history, features, Page.EATTESTATIONS, PagesArchitecture, undefined, () =>
        window.open(eattestationUrl),
    ),
]
