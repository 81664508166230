export const PrestadomRequestKeys = {
    ACTIVE_CONTRACTS_FOR_NEW_MISSION_CREATION: "ACTIVE_CONTRACTS_FOR_NEW_MISSION_CREATION",
    DASHBOARD_REQUEST: "DASHBOARD_REQUEST",
    GET_INTERVENTION_ZONES: "GET_INTERVENTION_ZONES",
    GET_SERVICE_TYPES: "GET_SERVICE_TYPES",
    GET_CONTRACTS: "GET_CONTRACTS",
    GET_PRICES_LIST: "GET_PRICES_LIST",
    INVOICE_DETAILS: "INVOICE_DETAILS",
    INVOICES_REQUEST: "INVOICES_REQUEST",
    INVOICE_EXPORT_REQUEST: "INVOICE_EXPORT_REQUEST",
    MISSION_DETAILS: "MISSION_DETAILS",
    MISSIONS_BENEFICIARY: "MISSIONS_BENEFICIARY",
    MISSION_BENEFICIARY_INFOS: "MISSION_BENEFICIARY_INFOS",
    MISSIONS_DOWNLOAD_ODM: "MISSIONS_DOWNLOAD_ODM",
    MISSIONS_ANNULATION: "MISSIONS_ANNULATION",
    MISSIONS_HISTORY_REQUEST: "MISSIONS_HISTORY_REQUEST",
    MISSIONS_EXPORT_REQUEST: "MISSIONS_EXPORT_REQUEST",
    MISSIONS_REQUEST: "MISSIONS_REQUEST",
    MISSIONS_UPDATE_INFORMATION: "MISSIONS_UPDATE_INFORMATION",
    MISSIONS_UPDATE_BCM_FILE: "MISSIONS_UPDATE_BCM_FILE",
    MISSIONS_CANCEL_MISSION: "MISSIONS_CANCEL_MISSION",
    NEW_MISSION_INPUT_GET_CHILDREN: "NEW_MISSION_INPUT_GET_CHILDREN",
    NEW_MISSION_INPUT_NEW_MISSION_FASTT: "NEW_MISSION_INPUT_NEW_MISSION_FASTT",
    NEW_MISSION_INPUT_NEW_MISSION_SORTIR_PLUS: "NEW_MISSION_INPUT_NEW_MISSION_SORTIR_PLUS",
    RECLAMATION_ATTACHEMENT: "RECLAMATION_ATTACHEMENT",
    RECLAMATION_NOTE_INFORMATION: "RECLAMATION_NOTE_INFORMATION",
    REQUEST_DETAILS: "REQUEST_DETAILS",
    REQUEST_ATTRIBUTION: "REQUEST_ATTRIBUTION",
    REQUESTS_READING_STATUS: "REQUESTS_READING_STATUS",
    REQUESTS_STATUS: "REQUESTS_STATUS",
    SERVICE_REQUEST_ID: "SERVICE_REQUEST_ID",
    SERVICE_REQUESTS_EXPORT_REQUEST: "SERVICE_REQUESTS_EXPORT_REQUEST",
    SERVICE_REQUESTS_HISTORY_REQUEST: "SERVICE_REQUESTS_HISTORY_REQUEST",
    SERVICE_REQUESTS_REQUEST: "SERVICE_REQUESTS_REQUEST",
    USER_REQUEST: "USER_REQUEST",
}
