import {FACTURATION, MISSIONNEMENT, SAISIE_MISSION} from "../files/permissionType"
import {Page} from "./pages"

/**
 * Architecture of the pages of the application.
 * Structure :
 * - breadcrumbText : the text print in a breadcrumb
 * - route : the route to go to the page
 * - routeWithParamDesc : a second route, with param
 * - routeWithParam : a function take all params for the routeWithParamDesc and building a route
 * - parent : the parent page
 * - parentDisconnect : the parent page if no user is connected
 * - permissions: User permissions needed to access the page
 * - featureFlag : Tell if a page is subject to feature flagging by feature name
 */
export const PagesArchitecture = {
    [Page.BYPASS_LOGIN]: {
        route: "/bypass-login",
        userConnected: false,
    },
    [Page.HOME]: {
        breadcrumbText: "Accueil",
        route: "/",
        userConnected: true,
    },
    [Page.CONTACT]: {
        breadcrumbText: "Contact",
        route: "/contact",
    },
    [Page.LOGIN]: {
        breadcrumbText: "Connexion",
        route: "/login",
    },
    [Page.PROFIL]: {
        breadcrumbText: "Profil",
        route: "/profil",
    },
    [Page.USER_PROFILE_AUTHENTICATION_INFORMATIONS]: {
        breadcrumbText: "Profil",
        route: "/profil/infos-connexion",
        userConnected: true,
        parent: Page.HOME,
    },
    [Page.USER_PROFILE_MAIN_INFORMATIONS]: {
        breadcrumbText: "Profil",
        route: "/profil/infos-principales",
        userConnected: true,
        parent: Page.HOME,
    },
    [Page.USER_PROFILE_PAYMENT_INFORMATIONS]: {
        breadcrumbText: "Profil",
        route: "/profil/infos-remboursement",
        userConnected: true,
        parent: Page.HOME,
        permissions: [FACTURATION],
    },

    [Page.USER_PROFILE_ATTESTATIONS]: {
        breadcrumbText: "Profil",
        route: "/profil/infos-attestations",
        userConnected: true,
        parent: Page.HOME,
    },
    [Page.LEGAL_NOTICE]: {
        parentDisconnect: Page.LOGIN,
        breadcrumbText: "Mentions Légales",
        route: "/mentions-legales",
        parent: Page.HOME,
    },
    [Page.NOT_FOUND]: {
        breadcrumbText: "Page introuvable",
        route: "/notfound",
    },
    [Page.TECHNICAL_ERROR]: {
        route: "/erreur-technique",
    },
    [Page.UNAUTHORIZED]: {
        breadcrumbText: "Accès interdit",
        route: "/unauthorized",
    },
    [Page.RECOVER_LOGIN_PASSWORD_WITH_IDENTIFIER]: {
        parent: Page.LOGIN,
        breadcrumbText: "Mot de passe oublié ?",
        route: "/mot-de-passe-oublie",
    },
    [Page.RECOVER_LOGIN_PASSWORD_WITH_MAIL]: {
        parent: Page.LOGIN,
        breadcrumbText: "Identifiant oublié ?",
        route: "/identifiant-oublie",
    },
    [Page.RESET_PASSWORD]: {
        breadcrumbText: "Réinitialiser son mot de passe",
        routeWithParamDesc: "/reinitialiser-mot-de-passe/:token",
        routeWithParam: token => "/reinitialiser-mot-de-passe/" + token,
    },
    [Page.WAITING_REQUESTS]: {
        breadcrumbText: "Demandes en attente",
        route: "/demandes-en-attente",
        userConnected: true,
        parent: Page.HOME,
        permissions: [MISSIONNEMENT],
    },
    [Page.REQUESTS_HISTORY]: {
        breadcrumbText: "Historique des demandes",
        route: "/historique-des-demandes",
        userConnected: true,
        parent: Page.HOME,
        permissions: [MISSIONNEMENT],
    },

    [Page.NEW_MISSION_INPUT_INIT]: {
        breadcrumbText: "Saisie d'une mission",
        route: "/saisie-mission/beneficaire",
        userConnected: true,
        parent: Page.HOME,
        permissions: [SAISIE_MISSION],
    },

    [Page.NEW_MISSION_INPUT_DETAILS]: {
        breadcrumbText: "Saisie d'une mission",
        route: "/saisie-mission/details-mission",
        userConnected: true,
        parent: Page.HOME,
        permissions: [SAISIE_MISSION],
    },

    [Page.CURRENT_MISSIONS]: {
        breadcrumbText: "Missions en cours",
        route: "/missions-en-cours",
        userConnected: true,
        parent: Page.HOME,
        permissions: [MISSIONNEMENT],
    },

    [Page.MISSIONS_HISTORY]: {
        breadcrumbText: "Historique des missions",
        route: "/historique-des-missions",
        userConnected: true,
        parent: Page.HOME,
        permissions: [MISSIONNEMENT],
    },

    [Page.INVOICES]: {
        breadcrumbText: "Factures",
        route: "/factures",
        userConnected: true,
        parent: Page.HOME,
        permissions: [FACTURATION],
    },
    [Page.PRICE_LIST]: {
        breadcrumbText: "Dispositions financières",
        route: "/dispositions-financieres",
        userConnected: true,
        parent: Page.HOME,
        permissions: [FACTURATION],
    },
    [Page.EATTESTATIONS]: {
        breadcrumbText: "E‑attestations",
        userConnected: true,
        parent: Page.HOME,
    },
}
