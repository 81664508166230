import {isArray, isEmpty} from "lodash"
import * as fromSessionStorage from "../../sessionStorage/sessionStorageReducer"
import * as fromRequests from "../../../shared-components/store/requests/requestsReducer"
import {CommonRequestKeys} from "../../../shared-components/common/constants/requests"

/**
 * Get the user token from the state.
 */
export const getUserToken = state => fromSessionStorage.getUserToken(state.sessionStorage)

/**
 * Get the expiration date.
 */
export const getExpirationDate = state => fromSessionStorage.getExpirationDate(state.sessionStorage)

/**
 * Verify if user is connected.
 */
export const isUserConnected = state => !!getUserToken(state)

/**
 * Verify if user token is fetching.
 */
export const isFetchingAuthority = state => fromRequests.isFetching(CommonRequestKeys.USER_AUTHORITY, state.requests)

/**
 * Get the authority error message from the state.
 */
export const getAuthorityErrorMessage = state =>
    fromRequests.getErrorMessage(CommonRequestKeys.USER_AUTHORITY, state.requests)

/**
 * Get user permissions from the store.
 */
export const getUserPermissions = state => fromSessionStorage.getUserPermissions(state.sessionStorage)

/**
 * Get user identity from the store.
 */
export const getUserDetails = state => fromSessionStorage.getUserDetails(state.sessionStorage)

/**
 * Get user profile from the store.
 */
export const getUserProfile = state => fromSessionStorage.getUserProfile(state.sessionStorage)

/**
 * Get user info from the store
 */
export const getUserInfo = state => fromSessionStorage.getUserInfo(state.sessionStorage)

/**
 * Get user login from the store.
 */
export const getUserLogin = state => getUserProfile(state).login

/**
 * Get user full name
 */
export const getUserFullname = state => {
    const userDetails = getUserProfile(state)
    return userDetails.socialReason
}

/**
 * Verify if user details is fetching ?
 */
export const isFetchingUserDetails = state => fromRequests.isFetching(CommonRequestKeys.USER_INFO, state.requests)

/**
 * Verifiy is recover login password request is fetching
 */
export const isFetchingRecoverLoginPassword = state =>
    fromRequests.isFetching(CommonRequestKeys.RECOVER_LOGIN_PASSWORD, state.requests)

/**
 * Return if authentication is bypassed
 */
export const wasAuthenticationBypassed = state => fromSessionStorage.getBypassedAuthentication(state.sessionStorage)

/**
 * Intervention zones
 */
export const getInterventionZones = state => fromSessionStorage.getInterventionZones(state.sessionStorage)

export const getActiveInterventionZone = state => {
    const allIZ = fromSessionStorage.getInterventionZones(state.sessionStorage)
    return isEmpty(allIZ) ? [] : allIZ.filter(el => el.interventionZoneStatus === "REFERENCE")
}

export const hasMultipleInterventionZones = state => {
    const zones = fromSessionStorage.getInterventionZones(state.sessionStorage)

    return isArray(zones) && zones.length > 1
}
