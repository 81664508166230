import {defaults} from "axios"

const SERVICE_ENDPOINT = "/api/prestadom/service"
const INVOICE_ENDPOINT = `${SERVICE_ENDPOINT}/factures`
const MISSION_BENEFICIARY_INFOS = "/api/service/beneficiaire"
const MISSION_ENDPOINT = `${SERVICE_ENDPOINT}/missions`
const NEW_MISSION_INPUT_ENDPOINT = `${SERVICE_ENDPOINT}/saisie-mission`
const RECLAMATION_ENDPOINT = `${SERVICE_ENDPOINT}/incidents`

/**
 * Endpoint to get data for the dashboard
 */
export const DASHBOARD_ENDPOINT = {
    getDashboardData: () => "/api/prestadom/service/tableau-bord",
}

/**
 * Endpoint to get data for the dashboard
 */
export const USER_ENDPOINT = {
    updateUserMainInformations: "/api/service/compte-utilisateur/profil/infos-principales",
}

/**
 * Endpoint to get intervention zone
 */
export const INTERVENTION_ZONE_ENDPOINT = `${SERVICE_ENDPOINT}/zones-interventions`
/**
 * Endpoint to get service request types
 */
export const SERVICE_TYPES_ENDPOINT = `${SERVICE_ENDPOINT}/types-prestations`

/**
 * Endpoint to get waiting request
 */
export const WAITING_REQUESTS_ENDPOINT = "/api/prestadom/service/demandes-en-attentes"

/**
 * Endpoint to handle service requests
 */
export const SERVICE_REQUESTS_ENDPOINT = {
    getServiceRequests: () => "/api/prestadom/service/demandes",
    getDetails: id => "/api/prestadom/service/demandes/" + id,
    updateStatus: id => "/api/prestadom/service/demandes/" + id + "/statut",
    updatePrestationRequestAttribution: id => "/api/prestadom/service/demandes/" + id + "/attribution",
    updateReadingStatus: id => "/api/prestadom/service/demandes/" + id + "/statutLecture",
}

/**
 * Endpoint to get service request history export file
 */
export const SERVICE_REQUESTS_EXPORT_ENDPOINT = "/api/prestadom/service/demandes-export"

/**
 * Endpoint to handle Invoices
 */
export const INVOICES_ENDPOINTS = {
    retrieveInvoices: INVOICE_ENDPOINT,
    invoiceDetails: id => `${INVOICE_ENDPOINT}/${id}`,
    export: `${INVOICE_ENDPOINT}/export`,
    contracts: `${INVOICE_ENDPOINT}/contrats`,
}
/**
 * Endpoint pour les grilles prestataire
 */
export const PRICE_LIST_ENDPOINTS = {
    getPricesLists: "/api/prestadom/service/grille-tarifaire",
}
/**
 * Endpoint to handle Missions
 */
export const MISSIONS_ENDPOINTS = {
    cancelMission: missionId => `${MISSION_ENDPOINT}/${missionId}/annuler`,
    getActiveContractsForNewMissionCreation: `${MISSION_ENDPOINT}/contrats-actifs-pour-saisie`,
    getCurrentMissions: () => `${MISSION_ENDPOINT}/en-cours`,
    getMissionsHistory: () => `${MISSION_ENDPOINT}/historique`,
    getMissionsHistoryExport: () => `${MISSION_ENDPOINT}/historique/export`,
    getMissionBeneficiary: () => `${MISSION_ENDPOINT}/recherche-beneficiaire`,
    getMissionBeneficiaryInfos: id => `${MISSION_BENEFICIARY_INFOS}/${id}`,
    getMissionDetails: missionId => `${MISSION_ENDPOINT}/${missionId}`,
    getODMForMission: () => `${MISSION_ENDPOINT}/mission-odm`,
    getAnnulationMission: () => `${MISSION_ENDPOINT}/mission-annulation`,
    updateMissionInformation: missionId => `${MISSION_ENDPOINT}/${missionId}`,
    updateBCMFile: missionId => `${MISSION_ENDPOINT}/${missionId}/bcm`,
}

export const NEW_MISSION_IMPUT_ENDPOINTS = {
    getChildren: `${NEW_MISSION_INPUT_ENDPOINT}/enfants`,
    newMissionFastt: `${NEW_MISSION_INPUT_ENDPOINT}/fastt`,
    newMissionSortirPlus: `${NEW_MISSION_INPUT_ENDPOINT}/sortir-plus`,
}

/**
 * Endpoints to handle reclamations
 */
export const RECLAMATION_ENDPOINTS = {
    addNewInformationNote: reclamationNumber => `${RECLAMATION_ENDPOINT}/${reclamationNumber}/note-information`,
    getReclamationAttachement: (reclamationNumber, attachementId) =>
        `${RECLAMATION_ENDPOINT}/${reclamationNumber}/piece-jointe/${attachementId}`,
}

/**
 * Endpoint to recover a parameter
 */
export const PARAMETER_ENDPOINT = {
    getParameterFromId: paramId => "/api/service/parametres/" + paramId,
}

/**
 * Endpoint to fetch profile information about the connected user
 */
export const GET_USER_PROFILE_INFO_ENDPOINT = "/api/service/compte-utilisateur/profil/informations"

/**
 * Endpoint to download a file from the filer
 */
export const FILER_ENDPOINT = {
    getFileFromPathId: pathId => "/api/service/filer/fichiers/" + pathId,
    getAllowedFileFromPathId: pathId => defaults.baseURL + "/api/service/filer/fichiers-autorises/" + pathId,
}
