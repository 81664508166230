export const PROCESSING = "En cours de traitement"
export const MISSION_ORDER_TO_DOWNLOAD = "Ordre de mission à télécharger"
export const INFORMATION_TO_SEND = "Informations à transmettre"
export const INVOICE_TO_SEND = "Facture à transmettre"

export const PARTIAL = "Partielle"
export const FINISHED = "Terminée"
export const CANCELED = "Annulée"

export const currentMissionStatusSelectOptions = [
    {
        id: 0,
        text: "Non renseigné",
        value: null,
    },
    {
        id: 1,
        text: PROCESSING,
        value: PROCESSING,
    },
    {
        id: 2,
        text: MISSION_ORDER_TO_DOWNLOAD,
        value: MISSION_ORDER_TO_DOWNLOAD,
    },
    {
        id: 3,
        text: INFORMATION_TO_SEND,
        value: INFORMATION_TO_SEND,
    },
    {
        id: 4,
        text: INVOICE_TO_SEND,
        value: INVOICE_TO_SEND,
    },
]

export const missionsHistoryStatusSelectOptions = [
    {
        id: 0,
        text: "Non renseigné",
        value: null,
    },
    {
        id: 1,
        text: PARTIAL,
        value: PARTIAL,
    },
    {
        id: 2,
        text: FINISHED,
        value: FINISHED,
    },
    {
        id: 3,
        text: CANCELED,
        value: CANCELED,
    },
]
