import {REQUEST_SUCCESS} from "../../../../shared-components/store/requests/requestsReducer"
import {PrestadomRequestKeys} from "../../../../constants/requests"

const interventionZones = (state = {}, action = {}) => {
    if (action.type === REQUEST_SUCCESS && action.requestKeyName === PrestadomRequestKeys.GET_INTERVENTION_ZONES) {
        return action.payload
    } else {
        return state
    }
}

export default interventionZones

export const getInterventionZones = (state = {}) => state
