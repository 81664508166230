import {combineReducers} from "redux"
import {
    SERVICE_REQUEST_ID_SUCCESS,
    SERVICE_REQUEST_EXIT_PAGE,
} from "../../../../actions/serviceRequests/serviceRequestsActionTypes"
import {REQUEST_SUCCESS, REQUEST_FAILURE} from "../../../../shared-components/store/requests/requestsReducer"
import {PrestadomRequestKeys} from "../../../../constants/requests"
import {READ} from "../../../../constants/serviceRequestReadingStatus"

const serviceRequestId = (state = {}, action = {}) => {
    switch (action.type) {
        case SERVICE_REQUEST_ID_SUCCESS:
            return {
                selectedServiceRequestId: action.id,
            }
        case SERVICE_REQUEST_EXIT_PAGE:
            return {
                selectedServiceRequestId: undefined,
            }
        default:
            return state
    }
}

const serviceRequestQuery = (state = null, action = {}) => {
    switch (action.type) {
        case REQUEST_SUCCESS:
            if (
                action.requestKeyName === PrestadomRequestKeys.SERVICE_REQUESTS_REQUEST ||
                action.requestKeyName === PrestadomRequestKeys.SERVICE_REQUESTS_HISTORY_REQUEST
            ) {
                return {serviceRequests: action.payload.entities.serviceRequests}
            } else if (action.requestKeyName === PrestadomRequestKeys.REQUESTS_READING_STATUS) {
                const serviceRequests = {
                    serviceRequests: {
                        ...state.serviceRequests,
                        [action.selectedId]: {
                            ...state.serviceRequests[action.selectedId],
                            checkedStatus: action.checkedStatus !== READ,
                        },
                    },
                }
                return serviceRequests
            }
            break
        case REQUEST_FAILURE:
            if (
                action.requestKeyName ===
                (PrestadomRequestKeys.SERVICE_REQUESTS_REQUEST || PrestadomRequestKeys.SERVICE_REQUESTS_HISTORY_REQUEST)
            ) {
                return state
            } else if (action.requestKeyName === PrestadomRequestKeys.REQUESTS_READING_STATUS) {
                return state
            }
            break
        default:
            return state
    }
    return state
}

const serviceRequest = combineReducers({
    serviceRequestId,
    serviceRequestQuery,
})

export default serviceRequest

/**
 * Get user details from the store.
 */
export const getSelectedServiceRequestId = state => state.serviceRequestId.selectedServiceRequestId

export const getServiceRequests = state => {
    return state.serviceRequestQuery ? state.serviceRequestQuery.serviceRequests : {}
}
