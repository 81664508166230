import {genericThunk} from "../../shared-components/actions/actionsUtils"
import {CommonRequestKeys} from "../../shared-components/common/constants/requests"
import {PARAMETER_ENDPOINT} from "../../constants/endpoints"
import HttpMethod from "../../shared-components/constants/httpMethods"

/**
 * Recover a parameter from its id
 */
export const getParameter = parameterId => dispatch => {
    const url = PARAMETER_ENDPOINT.getParameterFromId(parameterId)

    const payloadHandler = response => response.data

    return genericThunk(
        undefined,
        HttpMethod.GET,
        dispatch,
        url,
        undefined,
        CommonRequestKeys.PARAMETER_REQUEST,
        payloadHandler,
    )
}
