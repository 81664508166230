/**
 * The pages of the application.
 */
export const Page = {
    BYPASS_LOGIN: "BYPASS_LOGIN",
    CONTACT: "CONTACT",
    CURRENT_MISSIONS: "CURRENT_MISSIONS",
    EATTESTATIONS: "EATTESTATIONS",
    HOME: "HOME",
    INVOICES: "INVOICES",
    LEGAL_NOTICE: "LEGAL_NOTICE",
    LOGIN: "LOGIN",
    MISSIONS_HISTORY: "MISSIONS_HISTORY",
    NEW_MISSION_INPUT_INIT: "NEW_MISSION_INPUT_INIT",
    NEW_MISSION_INPUT_DETAILS: "NEW_MISSION_INPUT_DETAILS",
    NOT_FOUND: "NOT_FOUND",
    PRICE_LIST: "PRICE_LIST",
    RECOVER_LOGIN_PASSWORD_WITH_IDENTIFIER: "RECOVER_LOGIN_PASSWORD_WITH_IDENTIFIER",
    RECOVER_LOGIN_PASSWORD_WITH_MAIL: "RECOVER_LOGIN_PASSWORD_WITH_MAIL",
    REQUESTS_HISTORY: "REQUESTS_HISTORY",
    RESET_PASSWORD: "RESET_PASSWORD",
    TECHNICAL_ERROR: "TECHNICAL_ERROR",
    UNAUTHORIZED: "UNAUTHORIZED",
    USER_PROFILE_ATTESTATIONS: "USER_PROFILE_ATTESTATIONS",
    USER_PROFILE_AUTHENTICATION_INFORMATIONS: "USER_PROFILE_AUTHENTICATION_INFORMATIONS",
    USER_PROFILE_MAIN_INFORMATIONS: "USER_PROFILE_MAIN_INFORMATIONS",
    USER_PROFILE_PAYMENT_INFORMATIONS: "USER_PROFILE_PAYMENT_INFORMATIONS",
    WAITING_REQUESTS: "WAITING_REQUESTS",
}
