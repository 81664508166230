/**
 * Default values for global style for prestadom
 * The structure is a JSON containing key/value pairs.
 */
const prestadomStyle = {
    primaryColor: "#1d3561",
    secondaryColor: "#6bb6e6",
    tertiaryColor: "#a1b1bc",
    successColor: "#7bc200",
    warningColor: "#ffd600",
    dangerColor: "#e84136",
}

export default prestadomStyle
