export const defaultParameters = {
    eAttestationsDeclarantUrl: "domiserve.servidom.api.eattestations.declarants.url",
    homeHeaderTitle: "domiserve.prestadom.home.header.title",
    homeHeaderParagraph1: "domiserve.prestadom.home.header.paragraph.1",
    homeHeaderParagraph2: "domiserve.prestadom.home.header.paragraph.2",
    homeHeaderParagraph3: "domiserve.prestadom.home.header.paragraph.3",
    homeHeaderParagraph4: "domiserve.prestadom.home.header.paragraph.4",
    loginPageInformationMessage: "domiserve.prestadom.login.page.information.message",
    mailPrestatairesAddress: "domiserve.servidom.api.service.mail.prestataires",
    mailPrestationsAddress: "domiserve.servidom.api.service.mail.prestations",
}

let parameters = {...defaultParameters}

export default parameters
